/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { DashboardExtension } from '@dashboard/extension';

export const federatedModules: Record<string, {
    module: Promise<{
      default: DashboardExtension
    }>
  }> = {
  messaging_extension: {
    module: import('messaging_extension/messaging_extension'),
  },
  user_extension: {
    module: import('user_extension/user_extension'),
  },
  internal_test_ext: {
    module: import('internal_test_ext/internal_test_ext'),
  },
  integration_extension: {
    module: import('integration_extension/integration_extension'),
  },
  analytics_extension: {
    module: import('analytics_extension/analytics_extension'),
  },
  account_extension: {
    module: import('account_extension/account_extension'),
  },
  team_extension: {
    module: import('team_extension/team_extension'),
  },
  number_extension: {
    module: import('number_extension/number_extension'),
  },
  conversations_center_ext: {
    module: import('conversations_center_ext/conversations_center_ext'),
  },
  calendar_mgmt_extension: {
    module: import('calendar_mgmt_extension/calendar_mgmt_extension'),
  },
  voice_virtual_agent_extension: {
    module: import('voice_virtual_agent_extension/voice_virtual_agent_extension'),
  },
  live_monitoring_extension: {
    module: import('live_monitoring_extension/live_monitoring_extension'),
  },
  call_timeline_extension: {
    module: import('call_timeline_extension/call_timeline_extension'),
  },
  flow_editor_extension: {
    module: import('flow_editor_extension/flow_editor_extension'),
  },
  }
